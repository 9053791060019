import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';

export default function UpdateNews() {
  const { id } = useParams();
  const [article, setArticle] = useState(null);
  const [formData, setFormData] = useState({
    mainTitle: '',
    underTitle: '',
    categories: [],
    sections: []
  });
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [newCategory, setNewCategory] = useState('');
  const [removedPhotos, setRemovedPhotos] = useState([]); // Track removed photos

  useEffect(() => {
    fetchArticle();
  }, [id]);

  const fetchArticle = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(`https://news-4ol1.onrender.com/api/news/${id}`);
      const articleData = response.data;
      setArticle(articleData);

      setFormData({
        mainTitle: articleData.mainTitle || '',
        underTitle: articleData.underTitle || '',
        categories: articleData.categories || [],
        sections: Array.isArray(articleData.sections) ? articleData.sections.map(section => ({
          title: section.title || '',
          content: section.content || '',
          photos: section.photos || [], // Ensure photos is an array
          tables: Array.isArray(section.tables) ? section.tables.map(table => ({
            title: table.title || '',
            rows: table.rows || 3,
            columns: table.columns || 3,
            cells: Array.isArray(table.cells) ? table.cells : Array(3).fill().map(() => Array(3).fill(''))
          })) : []
        })) : []
      });
    } catch (error) {
      console.error('Error fetching article:', error);
      setError('Failed to fetch article');
    } finally {
      setIsLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSectionChange = (sectionIndex, field, value) => {
    setFormData(prev => {
      const updatedSections = [...prev.sections];
      updatedSections[sectionIndex] = {
        ...updatedSections[sectionIndex],
        [field]: value
      };
      return { ...prev, sections: updatedSections };
    });
  };

  const handlePhotoChange = async (sectionIndex, photoIndex, photo) => {
    if (!photo) return;
    
    const formDataForUpload = new FormData();
    formDataForUpload.append('photos', photo);
    formDataForUpload.append('photoSectionIndex', sectionIndex.toString());
    formDataForUpload.append('photoIndex', photoIndex.toString());

    try {
        const response = await axios.patch(
            `https://news-4ol1.onrender.com/api/news/${id}`,
            formDataForUpload,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            }
        );

        // Update the local state with the new photo
        if (response.data && response.data.sections) {
            setFormData(prev => {
                const updatedSections = [...prev.sections];
                // Store the old photo's public ID if it exists
                const oldPhoto = updatedSections[sectionIndex].photos[photoIndex];
                if (oldPhoto?.cloudinaryPublicId) {
                    setRemovedPhotos(prev => [...prev, oldPhoto.cloudinaryPublicId]);
                }
                
                // Update with new photo
                if (response.data.sections[sectionIndex]?.photos?.[photoIndex]) {
                    updatedSections[sectionIndex].photos[photoIndex] = 
                        response.data.sections[sectionIndex].photos[photoIndex];
                }
                return { ...prev, sections: updatedSections };
            });
        }
    } catch (error) {
        console.error('Error uploading photo:', error);
        alert('Failed to upload photo');
    }
};
  const removePhoto = (sectionIndex, photoIndex) => {
    const photo = formData.sections[sectionIndex].photos[photoIndex];

    // Add the photo's public ID to removedPhotos if it exists
    if (photo?.cloudinaryPublicId) {
      setRemovedPhotos(prev => [...prev, photo.cloudinaryPublicId]);
    }

    // Remove the photo from the section
    setFormData(prev => {
      const updatedSections = [...prev.sections];
      updatedSections[sectionIndex].photos = updatedSections[sectionIndex].photos.filter((_, i) => i !== photoIndex);
      return { ...prev, sections: updatedSections };
    });
  };

  const addPhoto = (sectionIndex) => {
    setFormData(prev => {
      const updatedSections = [...prev.sections];
      updatedSections[sectionIndex].photos.push({
        cloudinaryUrl: '',
        cloudinaryPublicId: ''
      });
      return { ...prev, sections: updatedSections };
    });
  };

  const addSection = () => {
    setFormData(prev => ({
      ...prev,
      sections: [...prev.sections, {
        title: '',
        content: '',
        photos: [],
        tables: []
      }]
    }));
  };

  const removeSection = (index) => {
    setFormData(prev => ({
      ...prev,
      sections: prev.sections.filter((_, i) => i !== index)
    }));
  };

  const addTable = (sectionIndex) => {
    setFormData(prev => {
      const updatedSections = [...prev.sections];
      if (!updatedSections[sectionIndex].tables) {
        updatedSections[sectionIndex].tables = [];
      }
      updatedSections[sectionIndex].tables.push({
        title: '',
        rows: 3,
        columns: 3,
        cells: Array(3).fill().map(() => Array(3).fill(''))
      });
      return { ...prev, sections: updatedSections };
    });
  };

  const removeTable = (sectionIndex, tableIndex) => {
    setFormData(prev => {
      const updatedSections = [...prev.sections];
      updatedSections[sectionIndex].tables = updatedSections[sectionIndex].tables.filter((_, i) => i !== tableIndex);
      return { ...prev, sections: updatedSections };
    });
  };

  const handleTableChange = (sectionIndex, tableIndex, field, value) => {
    setFormData(prev => {
      const updatedSections = [...prev.sections];
      const updatedTables = [...updatedSections[sectionIndex].tables];
      updatedTables[tableIndex] = {
        ...updatedTables[tableIndex],
        [field]: value
      };
      updatedSections[sectionIndex].tables = updatedTables;
      return { ...prev, sections: updatedSections };
    });
  };

  const handleCellChange = (sectionIndex, tableIndex, rowIndex, colIndex, value) => {
    setFormData(prev => {
      const updatedSections = [...prev.sections];
      const updatedTables = [...updatedSections[sectionIndex].tables];
      const updatedCells = [...updatedTables[tableIndex].cells];

      if (!updatedCells[rowIndex]) {
        updatedCells[rowIndex] = [];
      }
      updatedCells[rowIndex][colIndex] = value;

      updatedTables[tableIndex].cells = updatedCells;
      updatedSections[sectionIndex].tables = updatedTables;
      return { ...prev, sections: updatedSections };
    });
  };

  const addCategory = () => {
    if (newCategory.trim()) {
      setFormData(prev => ({
        ...prev,
        categories: [...prev.categories, newCategory.trim()]
      }));
      setNewCategory('');
    }
  };

  const removeCategory = (index) => {
    setFormData(prev => ({
      ...prev,
      categories: prev.categories.filter((_, i) => i !== index)
    }));
  };

  
const handleSubmit = async (e) => {
  e.preventDefault();
  try {
    // Create sections without modifying photos
    const sectionsWithoutPhotoChanges = formData.sections.map(section => {
      // Copy the section but omit the photos field
      const { photos, ...sectionWithoutPhotos } = section;
      return sectionWithoutPhotos;
    });

    const payload = {
      mainTitle: formData.mainTitle,
      underTitle: formData.underTitle,
      categories: JSON.stringify(formData.categories),
      sections: JSON.stringify(sectionsWithoutPhotoChanges)
    };

    const response = await axios.patch(
      `https://news-4ol1.onrender.com/api/news/content/${id}`,
      payload
    );

    if (response.data) {
      alert('Article updated successfully');
      // Don't reset removedPhotos or refetch the article since we're not handling photos
    }
  } catch (error) {
    console.error('Error updating article:', error);
    alert('Failed to update article');
  }
};

  if (isLoading) return <div className="text-center p-4">Loading...</div>;
  if (error) return <div className="text-center p-4 text-red-500">{error}</div>;
  if (!article) return <div className="text-center p-4">Article not found</div>;

  return (
    <div className="min-h-screen bg-gray-50 py-8" dir="rtl">
      <div className="container mx-auto px-4 max-w-5xl">
        <div className="bg-white rounded-lg shadow-lg p-8">
          <h1 className="text-3xl font-bold text-gray-800 mb-8 border-b pb-4">
            تحديث المقال
          </h1>

          <form onSubmit={handleSubmit} className="space-y-8">
            {/* Main Title */}
            <div className="space-y-2">
              <label className="text-lg font-semibold text-gray-700">
                العنوان الرئيسي
              </label>
              <input
                type="text"
                name="mainTitle"
                value={formData.mainTitle}
                onChange={handleInputChange}
                className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-400 focus:border-transparent transition text-right"
                placeholder="أدخل العنوان الرئيسي"
              />
            </div>

            {/* Under Title */}
            <div className="space-y-2">
              <label className="text-lg font-semibold text-gray-700">
                العنوان الفرعي
              </label>
              <input
                type="text"
                name="underTitle"
                value={formData.underTitle}
                onChange={handleInputChange}
                className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-400 focus:border-transparent transition text-right"
                placeholder="أدخل العنوان الفرعي"
              />
            </div>

            {/* Categories */}
            <div className="space-y-4">
              <label className="text-lg font-semibold text-gray-700">
                التصنيفات
              </label>
              <div className="flex gap-3">
                <button
                  type="button"
                  onClick={addCategory}
                  className="px-6 py-3 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition duration-200"
                >
                  إضافة تصنيف
                </button>
                <input
                  type="text"
                  value={newCategory}
                  onChange={(e) => setNewCategory(e.target.value)}
                  className="flex-1 p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-400 focus:border-transparent transition text-right"
                  placeholder="أضف تصنيفاً جديداً"
                />
              </div>
              <div className="flex flex-wrap gap-2 mt-3">
                {formData.categories.map((category, index) => (
                  <div
                    key={index}
                    className="bg-gray-100 px-4 py-2 rounded-full flex items-center gap-2"
                  >
                    <button
                      type="button"
                      onClick={() => removeCategory(index)}
                      className="text-red-500 hover:text-red-700 font-bold"
                    >
                      ×
                    </button>
                    <span className="text-gray-700">{category}</span>
                  </div>
                ))}
              </div>
            </div>

            {/* Sections */}
            <div className="space-y-6">
              <div className="flex justify-between items-center">
                <button
                  type="button"
                  onClick={addSection}
                  className="px-6 py-3 bg-green-500 text-white rounded-lg hover:bg-green-600 transition duration-200"
                >
                  إضافة قسم جديد
                </button>
                <label className="text-lg font-semibold text-gray-700">
                  الأقسام
                </label>
              </div>

              {formData.sections.map((section, sectionIndex) => (
                <div key={sectionIndex} className="bg-gray-50 rounded-lg p-6 space-y-6">
                  <div className="flex justify-start">
                    <button
                      type="button"
                      onClick={() => removeSection(sectionIndex)}
                      className="text-red-500 hover:text-red-700"
                    >
                      حذف القسم
                    </button>
                  </div>

                  {/* Section Content */}
                  <div className="space-y-4">
                    <input
                      type="text"
                      value={section.title}
                      onChange={(e) => handleSectionChange(sectionIndex, 'title', e.target.value)}
                      className="w-full p-3 border border-gray-300 rounded-lg text-right"
                      placeholder="عنوان القسم"
                    />
                    <textarea
                      value={section.content}
                      onChange={(e) => handleSectionChange(sectionIndex, 'content', e.target.value)}
                      className="w-full p-3 border border-gray-300 rounded-lg h-32 text-right"
                      placeholder="محتوى القسم"
                    />
                  </div>

                  {/* Photos */}
                  <div className="space-y-4">
                    <label className="block text-lg font-semibold text-gray-700">الصور</label>
                    <div className="space-y-4">
                      {section.photos && section.photos.map((photo, photoIndex) => (
                        <div key={photoIndex} className="flex items-center gap-4 bg-white p-4 rounded-lg">
                          <button
                            type="button"
                            onClick={() => removePhoto(sectionIndex, photoIndex)}
                            className="px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600"
                          >
                            حذف
                          </button>
                          <input
                            type="file"
                            onChange={(e) => handlePhotoChange(sectionIndex, photoIndex, e.target.files[0])}
                            className="flex-1 p-2 border border-gray-300 rounded-lg"
                          />
                          {photo.cloudinaryUrl && (
                            <img 
                              src={photo.cloudinaryUrl} 
                              alt={`صورة ${photoIndex}`} 
                              className="w-24 h-24 object-cover rounded-lg"
                            />
                          )}
                        </div>
                      ))}
                      <button
                        type="button"
                        onClick={() => addPhoto(sectionIndex)}
                        className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
                      >
                        إضافة صورة
                      </button>
                    </div>
                  </div>

                  {/* Tables */}
                  <div className="space-y-4">
                    <div className="flex justify-between items-center">
                      <button
                        type="button"
                        onClick={() => addTable(sectionIndex)}
                        className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
                      >
                        إضافة جدول
                      </button>
                      <label className="text-lg font-semibold text-gray-700">الجداول</label>
                    </div>
                    {section.tables && section.tables.map((table, tableIndex) => (
                      <div key={tableIndex} className="bg-white p-4 rounded-lg space-y-4">
                        <div className="flex justify-between items-center">
                          <button
                            type="button"
                            onClick={() => removeTable(sectionIndex, tableIndex)}
                            className="px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600"
                          >
                            حذف الجدول
                          </button>
                          <input
                            type="text"
                            value={table.title}
                            onChange={(e) => handleTableChange(sectionIndex, tableIndex, 'title', e.target.value)}
                            className="p-2 border border-gray-300 rounded-lg flex-1 mr-4 text-right"
                            placeholder="عنوان الجدول"
                          />
                        </div>
                        <div className="overflow-x-auto">
                          <table className="w-full border-collapse">
                            <tbody>
                              {Array(table.rows).fill().map((_, rowIndex) => (
                                <tr key={rowIndex}>
                                  {Array(table.columns).fill().map((_, colIndex) => (
                                    <td key={colIndex} className="border border-gray-300 p-1">
                                      <input
                                        type="text"
                                        value={table.cells[rowIndex]?.[colIndex] || ''}
                                        onChange={(e) => handleCellChange(
                                          sectionIndex,
                                          tableIndex,
                                          rowIndex,
                                          colIndex,
                                          e.target.value
                                        )}
                                        className="w-full p-2 border-none focus:ring-0 text-right"
                                      />
                                    </td>
                                  ))}
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>

            {/* Submit Button */}
            <button
              type="submit"
              className="w-full bg-blue-500 text-white py-4 px-6 rounded-lg hover:bg-blue-600 transition duration-200 text-lg font-semibold mt-8"
            >
              تحديث المقال
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}