import React from 'react'

export default function Adds() {
  return (
    <div className="w-full overflow-hidden rounded-2xl bg-white shadow-sm
      h-[150px] xs:h-[175px] sm:h-[200px] md:h-[225px] lg:h-[250px]">
      <img 
        src="/wou.jpg" 
        alt="Advertisement"
        className="w-full h-full object-cover hover:opacity-95 transition-opacity duration-300"
      />
    </div>
  )
}