import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import NavBar from "../components/NavBar";
import Bar from "../components/Bar";
import Footer from "../components/Footer";
import Weather from "../components/Weather";
import Adds from "../components/adds";

export default function NewsFeed() {
  const location = useLocation();
  const navigate = useNavigate();
  const [articles, setArticles] = useState([]);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [filter, setFilter] = useState("all");
  const [sortBy, setSortBy] = useState("newest");
  const [isWeatherOpen, setIsWeatherOpen] = useState(false);

  useEffect(() => {
    if (location.state?.selectedCategory) {
      setFilter(location.state.selectedCategory);
      navigate(location.pathname, { replace: true });
    }
  }, [location.state, navigate]);

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const response = await axios.get("https://news-4ol1.onrender.com/api/news/");
        setArticles(response.data);
        const allCategories = response.data.flatMap(article => article.categories);
        const uniqueCategories = [...new Set(allCategories)];
        setCategories(["all", ...uniqueCategories]);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
    fetchArticles();
  }, []);

  const getFirstMedia = (article) => {
    if (article.sections && article.sections.length > 0) {
      // Loop through all sections to find the first available media
      for (const section of article.sections) {
        if (section.photos && section.photos.length > 0) {
          const media = section.photos.find(photo => photo.cloudinaryUrl);
          if (media) {
            // Check if the media is a video (e.g., ends with .mp4, .mov, etc.)
            if (media.cloudinaryUrl.match(/\.(mp4|mov|avi|mkv)$/i)) {
              // Generate a thumbnail URL for the video
              const videoUrl = media.cloudinaryUrl;
              const thumbnailUrl = videoUrl.replace(/\.(mp4|mov|avi|mkv)$/i, '.jpg');
              return { type: 'video', url: thumbnailUrl, videoUrl };
            } else {
              // It's an image
              return { type: 'image', url: media.cloudinaryUrl };
            }
          }
        }
      }
    }
    return null;
  };
  const filteredArticles = articles
    .filter(article => 
      article.mainTitle.toLowerCase().includes(searchTerm.toLowerCase()) ||
      article.underTitle.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .filter(article => 
      filter === "all" ? true : article.categories.includes(filter)
    )
    .sort((a, b) => {
      if (sortBy === "newest") return new Date(b.createdAt) - new Date(a.createdAt);
      if (sortBy === "oldest") return new Date(a.createdAt) - new Date(b.createdAt);
      return 0;
    });

  const featuredArticles = filteredArticles.slice(0, 3);
  const remainingArticles = filteredArticles.slice(3);

  return (
    <div className="min-h-screen bg-gray-50">
    <NavBar />
    
    {/* Mobile Weather Toggle Button */}
    <div className="lg:hidden fixed top-24 right-4 z-50">
      <button
        onClick={() => setIsWeatherOpen(!isWeatherOpen)}
        className="bg-white p-2 rounded-full shadow-lg hover:shadow-xl transition-all"
      >
        <svg
          className="w-6 h-6 text-green-600"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path d="M3 15a4 4 0 004 4h9a5 5 0 10-.1-9.999 5.002 5.002 0 10-9.78 2.096A4.001 4.001 0 003 15z"></path>
        </svg>
      </button>
    </div>

    {/* Mobile Weather Panel */}
    <div 
      className={`lg:hidden fixed top-36 right-4 z-40 w-72 transform transition-transform duration-300 ${
        isWeatherOpen ? 'translate-x-0' : 'translate-x-full'
      }`}
    >
      <div className="bg-white rounded-lg shadow-lg p-4">
        <button
          onClick={() => setIsWeatherOpen(false)}
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
        >
          <svg className="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
        <Weather />
      </div>
    </div>

    <main className="container mx-auto px-4 sm:px-6 lg:px-8 pt-24 pb-16">
      {/* Category Filter */}
      <div className="sticky top-20 z-10 bg-gray-50/95 backdrop-blur-sm py-4 -mx-4 px-4 sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
        <div className="flex flex-nowrap overflow-x-auto hide-scrollbar gap-2 justify-start md:justify-center">
          {categories.map((category) => (
            <button
              key={category}
              onClick={() => setFilter(category)}
              className={`whitespace-nowrap px-4 py-2 rounded-full text-sm transition-all ${
                filter === category
                  ? "bg-green-600 text-white shadow-sm"
                  : "bg-white text-gray-600 hover:bg-gray-100 border border-gray-200"
              }`}
            >
              {category === "all" ? "الكل" : category}
            </button>
          ))}
        </div>
      </div>

      {/* Weather and Ads */}
      <div className="grid grid-cols-1 lg:grid-cols-4 gap-6 my-8">
        <div className="lg:col-span-3">
          <Adds />
        </div>
        <div className="hidden lg:block lg:col-span-1">
          <Weather />
        </div>
      </div>

      {loading ? (
        <div className="flex justify-center items-center h-64">
          <div className="animate-spin rounded-full h-12 w-12 border-4 border-green-600 border-t-transparent"></div>
        </div>
      ) : error ? (
        <div className="bg-red-50 text-red-600 p-4 rounded-lg text-center">
          {error}
        </div>
      ) : (
        <>
          {/* Featured Articles */}
          <section className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-12">
            {featuredArticles.map((article, index) => {
              const media = getFirstMedia(article);
              return (
                <article
                  key={article._id}
                  className={`bg-white rounded-2xl shadow-sm overflow-hidden hover:shadow-lg transition-shadow cursor-pointer ${
                    index === 0 ? "md:col-span-2 md:row-span-2" : ""
                  }`}
                  onClick={() => navigate(`/admin/article/${article._id}`)}
                >
                  <div className="aspect-video relative overflow-hidden">
                    {media ? (
                      media.type === 'video' ? (
                        <img
                          src={media.url}
                          alt={article.mainTitle}
                          className="w-full h-full object-cover transform hover:scale-105 transition-transform duration-300"
                        />
                      ) : (
                        <img
                          src={media.url}
                          alt={article.mainTitle}
                          className="w-full h-full object-cover transform hover:scale-105 transition-transform duration-300"
                        />
                      )
                    ) : (
                      <div className="w-full h-full bg-gray-200 flex items-center justify-center">
                        <svg className="w-12 h-12 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
                        </svg>
                      </div>
                    )}
                  </div>
                  <div className="p-6">
                    <div className="flex items-center gap-3 mb-4">
                      <span className="px-3 py-1 bg-green-50 text-green-700 rounded-full text-xs font-medium">
                        {article.categories[0]}
                      </span>
                      <time className="text-xs text-gray-500" dir="ltr">
                          {new Date(article.createdAt).toLocaleDateString("ar-TN", {
                           
                           day: "numeric",
                            month: "long",
                            year: "numeric",

                          })}
                        </time>
                    </div>
                    
                    <h2 className="font-bold text-xl mb-3 text-gray-900 line-clamp-2">
                      {article.mainTitle}
                    </h2>
                    
                    <p className="text-gray-600 text-sm line-clamp-2 mb-4">
                      {article.underTitle}
                    </p>
                    
                    <button className="inline-flex items-center text-green-600 hover:text-green-700 font-medium text-sm">
                      قراءة المزيد
                      <svg className="w-4 h-4 mr-2" viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clipRule="evenodd" />
                      </svg>
                    </button>
                  </div>
                </article>
              );
            })}
          </section>

          {/* Latest News */}
          <section className="space-y-8">
            <h2 className="text-2xl font-bold text-gray-900">آخر الأخبار</h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
              {remainingArticles.map((article) => {
                const media = getFirstMedia(article);
                return (
                  <article
                    key={article._id}
                    className="bg-white rounded-2xl shadow-sm overflow-hidden hover:shadow-lg transition-shadow cursor-pointer"
                    onClick={() => navigate(`/admin/article/${article._id}`)}
                  >
                    <div className="aspect-video relative overflow-hidden">
                      {media ? (
                        media.type === 'video' ? (
                          <img
                            src={media.url}
                            alt={article.mainTitle}
                            className="w-full h-full object-cover transform hover:scale-105 transition-transform duration-300"
                          />
                        ) : (
                          <img
                            src={media.url}
                            alt={article.mainTitle}
                            className="w-full h-full object-cover transform hover:scale-105 transition-transform duration-300"
                          />
                        )
                      ) : (
                        <div className="w-full h-full bg-gray-200 flex items-center justify-center">
                          <svg className="w-12 h-12 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
                          </svg>
                        </div>
                      )}
                    </div>
                    
                    <div className="p-6">
                      <div className="flex items-center gap-3 mb-4">
                        <span className="px-3 py-1 bg-green-50 text-green-700 rounded-full text-xs font-medium">
                          {article.categories[0]}
                        </span>
                        <time className="text-xs text-gray-500" dir="ltr">
                          {new Date(article.createdAt).toLocaleDateString("ar-TN", {
                           
                           day: "numeric",
                            month: "long",
                            year: "numeric",

                          })}
                        </time>
                      </div>
                      
                      <h3 className="font-bold text-lg text-gray-900 line-clamp-2 mb-4">
                        {article.mainTitle}
                      </h3>
                      
                      <button className="inline-flex items-center text-green-600 hover:text-green-700 font-medium text-sm">
                        قراءة المزيد
                        <svg className="w-4 h-4 mr-2" viewBox="0 0 20 20" fill="currentColor">
                          <path fillRule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clipRule="evenodd" />
                        </svg>
                      </button>
                    </div>
                  </article>
                );
              })}
            </div>
          </section>
        </>
      )}
    </main>

    <Bar />
    <Footer />
  </div>

  );
}