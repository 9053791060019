import React, { useState, useEffect } from "react";
import { User, Mail, Lock, Shield, Loader2, CheckCircle2, XCircle } from "lucide-react";
import { useNavigate } from "react-router-dom";
import {jwtDecode} from "jwt-decode";

const Register = () => {
  const navigate = useNavigate();

  // Verify user role using useEffect
 useEffect(() => {
  const verifyUserRole = (requiredRoles) => {
    try {
      // Get the token from sessionStorage
      const token = sessionStorage.getItem("authToken");

      if (!token) {
        console.error("No token found. Redirecting to home.");
        navigate("/"); // Redirect to home
        return;
      }

      // Decode the token
      const decodedToken = jwtDecode(token);

      // Check if the token is expired
      const currentTime = Date.now() / 1000; // Current time in seconds
      if (decodedToken.exp < currentTime) {
        console.error("Token expired. Redirecting to home.");
        sessionStorage.removeItem("token"); // Remove expired token
        navigate("/"); // Redirect to home
        return;
      }

      // Check if the user's role matches the required roles
      if (!requiredRoles.includes(decodedToken.role)) {
        console.error("User role is not authorized. Redirecting to home.");
        navigate("/"); // Redirect to home
        return;
      }

      // If everything is valid, allow access
      console.log("User is authorized.");
    } catch (error) {
      console.error("Error verifying token:", error);
      navigate("/"); // Redirect to home in case of any error
    }
  };

  // Call verifyUserRole with required roles
  verifyUserRole(["admin", ]); // Replace with the roles you want to verify
}, [navigate]); // Add navigate to the dependency array

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    role: "user",
  });

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setMessage("");

    try {
      const response = await fetch("https://news-4ol1.onrender.com/api/users", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      const data = await response.json();

      if (response.ok) {
        setMessage("User registered successfully!");
        setFormData({
          name: "",
          email: "",
          password: "",
          role: "user",
        });
        window.location.href = "/admin/dashboard";

      } else {
        setMessage(data.message || "Error registering user.");
      }
    } catch (error) {
      console.error("Error:", error);
      setMessage("An error occurred while registering the user.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex justify-center items-center min-h-screen bg-gradient-to-br from-green-50 to-yellow-100">
      <div className="bg-white p-8 rounded-2xl shadow-xl w-full max-w-md mx-4 relative overflow-hidden">
        <div className="absolute top-0 left-0 w-full h-2 bg-gradient-to-r from-green-500 to-yellow-500" />
        
        <h2 className="text-3xl font-bold text-center mb-8 text-gray-800">
          Create Account
        </h2>
        
        {message && (
          <div
            className={`flex items-center justify-center gap-2 p-4 rounded-lg mb-6 ${
              message.includes("successfully")
                ? "bg-green-50 text-green-700"
                : "bg-red-50 text-red-700"
            }`}
          >
            {message.includes("successfully") ? (
              <CheckCircle2 className="w-5 h-5" />
            ) : (
              <XCircle className="w-5 h-5" />
            )}
            {message}
          </div>
        )}

        <form onSubmit={handleSubmit} className="space-y-6">
          <div className="relative">
            <label className="block text-sm font-medium mb-2 text-gray-700" htmlFor="name">
              Full Name
            </label>
            <div className="relative">
              <User className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                className="w-full pl-10 pr-4 py-3 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-green-500 focus:border-transparent transition-all duration-200"
                placeholder="John Doe"
                required
              />
            </div>
          </div>

          <div className="relative">
            <label className="block text-sm font-medium mb-2 text-gray-700" htmlFor="email">
              Email Address
            </label>
            <div className="relative">
              <Mail className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="w-full pl-10 pr-4 py-3 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-green-500 focus:border-transparent transition-all duration-200"
                placeholder="john@example.com"
                required
              />
            </div>
          </div>

          <div className="relative">
            <label className="block text-sm font-medium mb-2 text-gray-700" htmlFor="password">
              Password
            </label>
            <div className="relative">
              <Lock className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
              <input
                type="password"
                id="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                className="w-full pl-10 pr-4 py-3 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-green-500 focus:border-transparent transition-all duration-200"
                placeholder="••••••••"
                required
              />
            </div>
          </div>

          <div className="relative">
            <label className="block text-sm font-medium mb-2 text-gray-700" htmlFor="role">
              Account Type
            </label>
            <div className="relative">
              <Shield className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
              <select
                id="role"
                name="role"
                value={formData.role}
                onChange={handleChange}
                className="w-full pl-10 pr-4 py-3 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-green-500 focus:border-transparent transition-all duration-200 appearance-none bg-white"
              >
                <option value="user">User</option>
                <option value="moderator">Moderator</option>
                <option value="admin">Admin</option>
              </select>
            </div>
          </div>

          <button
            type="submit"
            disabled={loading}
            className={`w-full py-3 rounded-lg font-medium transition-all duration-200 flex items-center justify-center gap-2 ${
              loading
                ? "bg-gray-100 text-gray-400 cursor-not-allowed"
                : "bg-gradient-to-r from-green-500 to-yellow-500 hover:from-green-600 hover:to-yellow-600 text-white transform hover:-translate-y-0.5"
            }`}
          >
            {loading ? (
              <>
                <Loader2 className="w-5 h-5 animate-spin" />
                Creating account...
              </>
            ) : (
              "Create Account"
            )}
          </button>
        </form>

        <p className="mt-8 text-center text-sm text-gray-500">
          Already have an account?{" "}
          <a href="#" className="font-medium text-green-500 hover:text-green-600 transition-colors">
            Sign in
          </a>
        </p>
      </div>
    </div>
  );
};

export default Register;