import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import {jwtDecode} from "jwt-decode";


const EditAlert = () => {
  const { id } = useParams(); 
  const navigate = useNavigate();

  // Verify user role using useEffect
 useEffect(() => {
  const verifyUserRole = (requiredRoles) => {
    try {
      // Get the token from sessionStorage
      const token = sessionStorage.getItem("authToken");

      if (!token) {
        console.error("No token found. Redirecting to home.");
        navigate("/"); // Redirect to home
        return;
      }

      // Decode the token
      const decodedToken = jwtDecode(token);

      // Check if the token is expired
      const currentTime = Date.now() / 1000; // Current time in seconds
      if (decodedToken.exp < currentTime) {
        console.error("Token expired. Redirecting to home.");
        sessionStorage.removeItem("token"); // Remove expired token
        navigate("/"); // Redirect to home
        return;
      }

      // Check if the user's role matches the required roles
      if (!requiredRoles.includes(decodedToken.role)) {
        console.error("User role is not authorized. Redirecting to home.");
        navigate("/"); // Redirect to home
        return;
      }

      // If everything is valid, allow access
      console.log("User is authorized.");
    } catch (error) {
      console.error("Error verifying token:", error);
      navigate("/"); // Redirect to home in case of any error
    }
  };

  // Call verifyUserRole with required roles
  verifyUserRole(["admin", "moderator"]); // Replace with the roles you want to verify
}, [navigate]); // Add navigate to the dependency array

  const [alertData, setAlertData] = useState({
    new: '',
    alert: false,
    show: true,
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const [submitLoading, setSubmitLoading] = useState(false);

  useEffect(() => {
    const fetchAlert = async () => {
      try {
        const response = await axios.get(`https://news-4ol1.onrender.com/api/alerts/${id}`);
        setAlertData(response.data);
        setError('');
      } catch (err) {
        setError('فشل في جلب بيانات التنبيه. الرجاء المحاولة لاحقاً.');
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchAlert();
  }, [id]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setAlertData({
      ...alertData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitLoading(true);
    setMessage('');
    try {
      await axios.patch(`https://news-4ol1.onrender.com/api/alerts/${id}`, alertData);
      setMessage('تم تحديث التنبيه بنجاح!');
      window.location.href = '/admin/allalert';

    } catch (err) {
      setMessage('فشل في تحديث التنبيه. الرجاء المحاولة مرة أخرى.');
      console.error(err);
    } finally {
      setSubmitLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-gradient-to-br from-green-50 to-yellow-50 py-12 px-4 flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-4 border-green-500 border-t-transparent"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen bg-gradient-to-br from-green-50 to-yellow-50 py-12 px-4 flex items-center justify-center">
        <div className="bg-red-100 text-red-700 p-4 rounded-lg border-2 border-red-300 text-center">
          {error}
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-green-50 to-yellow-50 py-12 px-4">
      <div className="max-w-xl mx-auto">
        <div className="bg-white rounded-2xl shadow-2xl overflow-hidden">
          <div className="bg-gradient-to-r from-green-600 to-yellow-500 p-6">
            <h1 className="text-3xl font-bold text-white text-center">
              تعديل التنبيه
            </h1>
          </div>
          
          <div className="p-8">
            <form onSubmit={handleSubmit} className="space-y-6">
              <div className="relative">
                <label htmlFor="new" className="block text-lg font-semibold text-gray-700 mb-2">
                  رسالة التنبيه
                </label>
                <input
                  type="text"
                  id="new"
                  name="new"
                  value={alertData.new}
                  onChange={handleChange}
                  className="w-full px-4 py-3 rounded-lg border-2 border-green-200 focus:border-green-500 focus:ring-4 focus:ring-green-100 transition-all duration-200 text-gray-700 text-lg"
                  placeholder="أدخل رسالة التنبيه هنا..."
                  required
                />
              </div>

              <div className="space-y-4">
                <label className="flex items-center justify-between p-4 rounded-lg bg-gradient-to-r from-green-50 to-yellow-50">
                  <span className="text-lg font-medium text-gray-700">
                    تحديد كتنبيه مهم
                  </span>
                  <div className="relative">
                    <input
                      type="checkbox"
                      id="alert"
                      name="alert"
                      checked={alertData.alert}
                      onChange={handleChange}
                      className="sr-only"
                    />
                    <div
                      className={`block w-14 h-8 rounded-full transition-colors duration-200 ease-in-out ${
                        alertData.alert ? 'bg-green-500' : 'bg-gray-300'
                      }`}
                    />
                    <div
                      className={`absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition-transform duration-200 ease-in-out ${
                        alertData.alert ? 'transform translate-x-6' : 'transform translate-x-0'
                      }`}
                    />
                  </div>
                </label>

                <label className="flex items-center justify-between p-4 rounded-lg bg-gradient-to-r from-green-50 to-yellow-50">
                  <span className="text-lg font-medium text-gray-700">
                    عرض التنبيه
                  </span>
                  <div className="relative">
                    <input
                      type="checkbox"
                      id="show"
                      name="show"
                      checked={alertData.show}
                      onChange={handleChange}
                      className="sr-only"
                    />
                    <div
                      className={`block w-14 h-8 rounded-full transition-colors duration-200 ease-in-out ${
                        alertData.show ? 'bg-yellow-500' : 'bg-gray-300'
                      }`}
                    />
                    <div
                      className={`absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition-transform duration-200 ease-in-out ${
                        alertData.show ? 'transform translate-x-6' : 'transform translate-x-0'
                      }`}
                    />
                  </div>
                </label>
              </div>

              <button
                type="submit"
                disabled={submitLoading}
                className={`w-full py-4 rounded-lg text-white text-lg font-bold transition-all duration-300 transform hover:-translate-y-1 hover:shadow-lg ${
                  submitLoading
                    ? 'bg-gray-400 cursor-not-allowed'
                    : 'bg-gradient-to-r from-green-500 to-yellow-500 hover:from-green-600 hover:to-yellow-600'
                }`}
              >
                {submitLoading ? (
                  <div className="flex items-center justify-center">
                    <div className="animate-spin rounded-full h-6 w-6 border-4 border-white border-t-transparent"></div>
                    <span className="mr-3">جارٍ التحديث...</span>
                  </div>
                ) : (
                  'تحديث التنبيه'
                )}
              </button>
            </form>

            {message && (
              <div className={`mt-6 p-4 rounded-lg text-center text-lg font-semibold animate-fadeIn ${
                message.includes('نجاح')
                  ? 'bg-green-100 text-green-700 border-2 border-green-300'
                  : 'bg-red-100 text-red-700 border-2 border-red-300'
              }`}>
                {message}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditAlert;