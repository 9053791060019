import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

export default function NavBar() {
  const [categories, setCategories] = useState([]);
  const [news, setNews] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredNews, setFilteredNews] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const dropdownRef = useRef(null);
  const navigate = useNavigate();

  const getDisplayCategories = () => {
    const mainCategories = categories.slice(0, 4);
    const extraCategories = categories.slice(4);
    return { mainCategories, extraCategories };
  };

  useEffect(() => {
    const fetchCategoriesAndNews = async () => {
      try {
        const response = await axios.get("https://news-4ol1.onrender.com/api/news/");
        const allNews = response.data;
        const allCategories = allNews.flatMap((article) => article.categories);
        const uniqueCategories = [...new Set(allCategories)];
        setCategories(uniqueCategories);
        setNews(allNews);
      } catch (error) {
        console.error("Error fetching data in NavBar:", error);
      }
    };
    fetchCategoriesAndNews();
  }, []);

  useEffect(() => {
    if (searchQuery) {
      const filtered = news.filter(
        (article) =>
          article.mainTitle &&
          article.mainTitle.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setFilteredNews(filtered);
      setDropdownOpen(filtered.length > 0);
    } else {
      setFilteredNews([]);
      setDropdownOpen(false);
    }
  }, [searchQuery, news]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleCategoryClick = (category) => {
    navigate("/", { state: { selectedCategory: category } });
    window.scrollTo(0, 0);
    setIsMenuOpen(false);
  };

  const handleHomeClick = () => {
    navigate("/");
    window.scrollTo(0, 0);
    setIsMenuOpen(false);
  };

  const handleSearchClick = (id) => {
    navigate(`/admin/article/${id}`);
    setSearchQuery("");
    setDropdownOpen(false);
    setIsMenuOpen(false);
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <nav className="fixed top-0 right-0 left-0 bg-white shadow-lg z-50 backdrop-blur-sm bg-white/90" dir="rtl">
      <div className="max-w-7xl mx-auto px-2 sm:px-4 lg:px-8">
        <div className="flex items-center justify-between h-16 md:h-20">
          {/* Logo and Title */}
          <div className="flex items-center space-x-2 md:space-x-4 hover:transform hover:scale-105 transition-all duration-300">
            <div className="w-10 h-10 md:w-12 md:h-12 bg-gradient-to-br from-green-600 to-green-800 rounded-full p-0.5">
              <div className="w-full h-full bg-white rounded-full p-0.5">
                <img
                  src="/al wouthou7 1.png"
                  alt="Logo"
                  className="w-full h-full object-cover rounded-full hover:rotate-3 transition-all duration-300"
                />
              </div>
            </div>
            <h1 className="text-xl md:text-2xl lg:text-3xl font-black bg-gradient-to-r from-green-700 to-green-900 bg-clip-text text-transparent mr-2">
              موقع الوضوح
            </h1>
          </div>

          {/* Desktop Navigation */}
          <div className="hidden lg:flex items-center gap-6 mr-4">
            <div
              className="text-lg font-medium text-green-900 hover:text-green-700 cursor-pointer relative group"
              onClick={handleHomeClick}
            >
              <span className="relative z-10">الرئيسية</span>
              <span className="absolute bottom-0 right-0 w-full h-0.5 bg-yellow-500 transform scale-x-0 group-hover:scale-x-100 transition-transform duration-300"></span>
            </div>

            {categories.length > 0 && getDisplayCategories().mainCategories.map((category, index) => (
              <div
                key={index}
                onClick={() => handleCategoryClick(category)}
                className="text-lg font-medium text-green-900 hover:text-green-700 cursor-pointer relative group"
              >
                <span className="relative z-10">{category}</span>
                <span className="absolute bottom-0 right-0 w-full h-0.5 bg-yellow-500 transform scale-x-0 group-hover:scale-x-100 transition-transform duration-300"></span>
              </div>
            ))}

            {categories.length > 4 && (
              <div className="relative group">
                <div className="text-lg font-medium text-green-900 hover:text-green-700 cursor-pointer">
                  المزيد
                  <span className="mr-1 font-bold">↧</span>
                </div>
                <div className="absolute right-0 mt-2 w-48 bg-white rounded-lg shadow-xl opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-300">
                  {getDisplayCategories().extraCategories.map((category, index) => (
                    <div
                      key={index}
                      onClick={() => handleCategoryClick(category)}
                      className="px-4 py-2 text-base text-green-900 hover:bg-green-50 cursor-pointer text-right"
                    >
                      {category}
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>

          {/* Search Bar - Desktop */}
          <div className="hidden md:block flex-1 max-w-xl mx-4">
            <div className="relative">
              <input
                type="text"
                placeholder="ابحث عن الأخبار"
                className="w-full border-2 border-green-100 rounded-full px-12 py-2 focus:outline-none focus:ring-2 focus:ring-green-500/40 focus:border-green-500 text-right"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              <span className="absolute right-4 top-1/2 transform -translate-y-1/2">
                <svg className="w-5 h-5 text-green-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                </svg>
              </span>

              {dropdownOpen && (
                <div className="absolute right-0 left-0 bg-white/95 backdrop-blur-md border border-green-100 rounded-2xl mt-2 max-h-80 overflow-y-auto shadow-xl z-50">
                  {filteredNews.map((article) => (
                    <div
                      key={article._id}
                      className="px-4 py-3 hover:bg-green-50/80 cursor-pointer transition-all duration-200"
                      onClick={() => handleSearchClick(article._id)}
                    >
                      <div className="text-base font-bold text-green-900">
                        {article.mainTitle || "عنوان غير متوفر"}
                      </div>
                      <div className="text-sm text-green-600/80">
                        {article.underTitle || "وصف غير متوفر"}
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>

          {/* Mobile Menu Button */}
          <button
            onClick={toggleMenu}
            className="lg:hidden text-green-800 hover:text-green-600 focus:outline-none p-2"
          >
            <svg
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              {isMenuOpen ? (
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12"/>
              ) : (
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16"/>
              )}
            </svg>
          </button>
        </div>

        {/* Mobile Menu */}
        <div
          className={`lg:hidden transition-all duration-300 ease-in-out ${
            isMenuOpen ? "max-h-screen opacity-100" : "max-h-0 opacity-0 overflow-hidden"
          }`}
        >
          {/* Mobile Search */}
          <div className="px-2 py-3">
            <div className="relative">
              <input
                type="text"
                placeholder="ابحث عن الأخبار"
                className="w-full border-2 border-green-100 rounded-full px-12 py-2 focus:outline-none focus:ring-2 focus:ring-green-500/40 focus:border-green-500 text-right"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              <span className="absolute right-4 top-1/2 transform -translate-y-1/2">
                <svg className="w-5 h-5 text-green-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                </svg>
              </span>
            </div>

            {/* Mobile Search Results */}
            {dropdownOpen && (
              <div className="mt-2 bg-white rounded-xl shadow-lg border border-green-100 overflow-hidden">
                {filteredNews.map((article) => (
                  <div
                    key={article._id}
                    className="p-3 border-b border-green-50 last:border-b-0 hover:bg-green-50 transition-colors duration-200"
                    onClick={() => handleSearchClick(article._id)}
                  >
                    <div className="font-bold text-green-900 mb-1">
                      {article.mainTitle || "عنوان غير متوفر"}
                    </div>
                    <div className="text-sm text-green-600">
                      {article.underTitle || "وصف غير متوفر"}
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>

          {/* Mobile Navigation Links */}
          <div className="px-2 py-3 space-y-1">
            <div
              className="block text-lg font-medium text-green-900 hover:text-green-700 cursor-pointer p-3 rounded-lg hover:bg-green-50 transition-colors duration-200"
              onClick={handleHomeClick}
            >
              الرئيسية
            </div>
            {categories.map((category, index) => (
              <div
                key={index}
                onClick={() => handleCategoryClick(category)}
                className="block text-lg font-medium text-green-900 hover:text-green-700 cursor-pointer p-3 rounded-lg hover:bg-green-50 transition-colors duration-200"
              >
                {category}
              </div>
            ))}
          </div>
        </div>
      </div>
    </nav>
  );
}