import React, { useState, useEffect } from "react";
import axios from "axios";
import { motion, AnimatePresence } from "framer-motion";
import { 
  Sun, Cloud, CloudRain, Wind, Droplet, 
  ThermometerSun, ChevronRight, ChevronLeft, Clock
} from "lucide-react";

const Weather = () => {
  const [weatherData, setWeatherData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentSection, setCurrentSection] = useState(0);
  const [direction, setDirection] = useState(0);
  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const timer = setInterval(() => setCurrentTime(new Date()), 1000);
    
    axios
      .get("https://news-4ol1.onrender.com/api/weather")
      .then((response) => {
        setWeatherData(response.data);
        setLoading(false);
      })
      .catch((err) => {
        setError("فشل في جلب بيانات الطقس");
        setLoading(false);
      });

    return () => clearInterval(timer);
  }, []);

  const getWeatherIcon = (temp, precip, size = 40) => {
    if (precip > 0.5) return <CloudRain className="text-blue-200" size={size} />;
    if (temp > 25) return <Sun className="text-yellow-300" size={size} />;
    return <Cloud className="text-gray-200" size={size} />;
  };

  const sections = [
    {
      title: "الحالي",
      render: () => {
        const { hourly } = weatherData;
        const currentHourIndex = currentTime.getHours();
        const currentData = {
          temp: hourly.temperature_2m[currentHourIndex],
          precip: hourly.precipitation[currentHourIndex],
          windSpeed: hourly.windspeed_10m[currentHourIndex],
          pressure: hourly.pressure_msl[currentHourIndex],
          clouds: hourly.cloudcover[currentHourIndex],
          uvIndex: hourly.uv_index[currentHourIndex],
        };

        return (
          <div className="p-3 text-white">
            <div className="flex justify-between items-start mb-3">
              <div className="flex flex-col">
                <div className="text-3xl font-bold">{Math.round(currentData.temp)}°</div>
                <div className="text-xs text-gray-200">
                  {currentTime.toLocaleTimeString('ar-US', { hour: '2-digit', minute: '2-digit' })}
                </div>
              </div>
              {getWeatherIcon(currentData.temp, currentData.precip, 32)}
            </div>

            <div className="grid grid-cols-3 gap-2">
              <div className="bg-white/10 rounded-lg p-2">
                <div className="flex items-center justify-center mb-1">
                  <Droplet className="text-blue-300" size={14} />
                </div>
                <div className="text-center text-[10px]">
                  <div>{currentData.precip}</div>
                  <div className="text-gray-300">ملم</div>
                </div>
              </div>

              <div className="bg-white/10 rounded-lg p-2">
                <div className="flex items-center justify-center mb-1">
                  <Wind className="text-gray-300" size={14} />
                </div>
                <div className="text-center text-[10px]">
                  <div>{currentData.windSpeed}</div>
                  <div className="text-gray-300">كم/س</div>
                </div>
              </div>

              <div className="bg-white/10 rounded-lg p-2">
                <div className="flex items-center justify-center mb-1">
                  <ThermometerSun className="text-yellow-300" size={14} />
                </div>
                <div className="text-center text-[10px]">
                  <div>{currentData.uvIndex}</div>
                  <div className="text-gray-300">UV</div>
                </div>
              </div>
            </div>

            <div className="mt-2 text-xs text-center text-gray-200">
              {currentData.clouds}% غيوم | {currentData.pressure} هكتوباسكال
            </div>
          </div>
        );
      }
    },
    {
      title: "التوقعات القادمة",
      render: () => {
        const { hourly } = weatherData;
        const days = [];
        for(let i = 0; i < 5; i++) {
          const dayData = {
            date: new Date(hourly.time[i * 24]),
            temp: hourly.temperature_2m[i * 24],
            precip: hourly.precipitation[i * 24],
          };
          days.push(dayData);
        }

        return (
          <div className="p-2 text-white">
            <div className="space-y-2">
              {days.map((day, idx) => (
                <div key={idx} className="bg-white/10 rounded-lg p-2 flex justify-between items-center">
                  <div className="text-xs">
                    {day.date.toLocaleDateString('ar-us', { weekday: 'short' })}
                  </div>
                  <div className="flex items-center gap-2">
                    {getWeatherIcon(day.temp, day.precip, 16)}
                    <span className="text-xs">{Math.round(day.temp)}°</span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        );
      }
    },
    {
      title: "ساعات",
      render: () => {
        const { hourly } = weatherData;
        const currentHour = currentTime.getHours();
        const nextHours = [];
        
        for(let i = 0; i < 6; i++) {
          const hourIndex = (currentHour + i) % 24;
          nextHours.push({
            time: new Date(hourly.time[hourIndex]),
            temp: hourly.temperature_2m[hourIndex],
            precip: hourly.precipitation[hourIndex],
            wind: hourly.windspeed_10m[hourIndex],
          });
        }

        return (
          <div className="p-2 text-white">
            <div className="grid grid-cols-2 gap-2">
              {nextHours.map((hour, idx) => (
                <div key={idx} className="bg-white/10 rounded-lg p-2">
                  <div className="text-[10px] text-gray-200">
                    {hour.time.toLocaleTimeString('ar-us', { hour: '2-digit', minute: '2-digit' })}
                  </div>
                  <div className="flex items-center justify-between mt-1">
                    <span className="text-xs">{Math.round(hour.temp)}°</span>
                    <div className="flex items-center gap-1">
                      <Wind className="text-gray-300" size={12} />
                      <span className="text-[10px]">{hour.wind}</span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        );
      }
    }
  ];

  if (loading) return <div className="text-white text-sm">جاري التحميل...</div>;
  if (error) return <div className="text-red-500 text-sm">{error}</div>;

  const switchSection = (direction) => {
    const newIndex = (currentSection + direction + sections.length) % sections.length;
    setDirection(direction);
    setCurrentSection(newIndex);
  };

  return (
    <div 
      className="w-[250px] h-[250px] bg-gradient-to-br from-blue-500 via-blue-600 to-blue-700 rounded-2xl shadow-xl relative overflow-hidden"
      dir="rtl"
    >
      <div className="absolute top-2 right-2 text-white text-sm font-semibold flex items-center gap-1">
        <Clock className="w-3 h-3" />
        {sections[currentSection].title}
      </div>
      
      <AnimatePresence initial={false} custom={direction}>
        <motion.div
          key={currentSection}
          initial={{ opacity: 0, x: direction > 0 ? -300 : 300 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: direction > 0 ? 300 : -300 }}
          transition={{ type: "spring", stiffness: 300, damping: 30 }}
          className="absolute inset-0 pt-8"
        >
          {sections[currentSection].render()}
        </motion.div>
      </AnimatePresence>

      <div className="absolute bottom-2 left-1/2 transform -translate-x-1/2 flex gap-1">
        {sections.map((_, idx) => (
          <div
            key={idx}
            className={`w-1 h-1 rounded-full ${
              idx === currentSection ? "bg-white" : "bg-white/30"
            }`}
          />
        ))}
      </div>

      <motion.button 
        onClick={() => switchSection(1)} 
        whileHover={{ scale: 1.1 }}
        className="absolute left-2 top-1/2 -translate-y-1/2 bg-white/20 rounded-full p-1"
      >
        <ChevronLeft className="text-white" size={16} />
      </motion.button>

      <motion.button 
        onClick={() => switchSection(-1)} 
        whileHover={{ scale: 1.1 }}
        className="absolute right-2 top-1/2 -translate-y-1/2 bg-white/20 rounded-full p-1"
      >
        <ChevronRight className="text-white" size={16} />
      </motion.button>
    </div>
  );
};

export default Weather;