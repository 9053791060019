import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

export default function CreateNew() {
  const navigate = useNavigate();

  const [formDetails, setFormDetails] = useState({
    mainTitle: "",
    underTitle: "",
  });

  const [selectedCategories, setSelectedCategories] = useState([]);
  const predefinedCategories = [
    "أخبار محلية",
    "أخبار عالمية",
    "رياضة",
    "اقتصاد",
    "تكنولوجيا",
    "ثقافة",
    "فن",
    "صحة",
    "تعليم",
    "سياسة",
  ];

  const [sections, setSections] = useState([
    { title: "", content: "", photos: [], tables: [] },
  ]);

  const [tableConfig, setTableConfig] = useState({ rows: 3, columns: 3 });
  const [isTableModalOpen, setIsTableModalOpen] = useState(false);
  const [currentSectionIndex, setCurrentSectionIndex] = useState(null);

  const handleCategoryChange = (category) => {
    setSelectedCategories((prevCategories) =>
      prevCategories.includes(category)
        ? prevCategories.filter((cat) => cat !== category)
        : [...prevCategories, category]
    );
  };

  const handleFormDetailsChange = (e) => {
    const { name, value } = e.target;
    setFormDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const handleSectionChange = (index, e) => {
    const { name, value } = e.target;
    setSections((prevSections) =>
      prevSections.map((section, i) =>
        i === index ? { ...section, [name]: value } : section
      )
    );
  };

  const handleAddSection = () => {
    setSections((prevSections) => [
      ...prevSections,
      { title: "", content: "", photos: [], tables: [] },
    ]);
  };

  const handleRemoveSection = (indexToRemove) => {
    if (sections.length > 1) {
      setSections((prevSections) =>
        prevSections.filter((_, index) => index !== indexToRemove)
      );
    }
  };

  const handleFileUpload = (event, sectionIndex) => {
    const files = Array.from(event.target.files);
    setSections(prevSections =>
      prevSections.map((section, idx) =>
        idx === sectionIndex
          ? {
              ...section,
              photos: [...section.photos, ...files]
            }
          : section
      )
    );
  };

  const handleRemovePhoto = (sectionIndex, photoIndex) => {
    setSections((prevSections) =>
      prevSections.map((section, i) =>
        i === sectionIndex
          ? {
              ...section,
              photos: section.photos.filter((_, pIndex) => pIndex !== photoIndex),
            }
          : section
      )
    );
  };

  const handleOpenTableModal = (index) => {
    setCurrentSectionIndex(index);
    setIsTableModalOpen(true);
  };

  const handleAddTable = () => {
    const newTable = {
      title: "",
      rows: tableConfig.rows,
      columns: tableConfig.columns,
      cells: Array.from({ length: tableConfig.rows }, () =>
        Array.from({ length: tableConfig.columns }, () => "")
      ),
    };

    setSections((prevSections) =>
      prevSections.map((section, i) =>
        i === currentSectionIndex
          ? { ...section, tables: [...section.tables, newTable] }
          : section
      )
    );

    setIsTableModalOpen(false);
    setTableConfig({ rows: 3, columns: 3 });
  };

  const handleRemoveTable = (sectionIndex, tableIndex) => {
    setSections((prevSections) =>
      prevSections.map((section, i) =>
        i === sectionIndex
          ? {
              ...section,
              tables: section.tables.filter((_, tIndex) => tIndex !== tableIndex),
            }
          : section
      )
    );
  };

  const handleTableTitleChange = (sectionIndex, tableIndex, e) => {
    const { value } = e.target;
    setSections((prevSections) =>
      prevSections.map((section, i) =>
        i === sectionIndex
          ? {
              ...section,
              tables: section.tables.map((table, tIndex) =>
                tIndex === tableIndex ? { ...table, title: value } : table
              ),
            }
          : section
      )
    );
  };

  const handleTableCellChange = (sectionIndex, tableIndex, rowIndex, colIndex, value) => {
    setSections((prevSections) =>
      prevSections.map((section, i) =>
        i === sectionIndex
          ? {
              ...section,
              tables: section.tables.map((table, tIndex) =>
                tIndex === tableIndex
                  ? {
                      ...table,
                      cells: table.cells.map((row, rIdx) =>
                        rIdx === rowIndex
                          ? row.map((cell, cIdx) => (cIdx === colIndex ? value : cell))
                          : row
                      ),
                    }
                  : table
              ),
            }
          : section
      )
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const formData = new FormData();
  
    // Add basic details
    formData.append("mainTitle", formDetails.mainTitle);
    formData.append("underTitle", formDetails.underTitle);
    formData.append("categories", JSON.stringify(selectedCategories));
  
    // Process sections and photos
    const sectionsWithoutPhotos = sections.map(section => ({
      title: section.title,
      content: section.content,
      photos: [], // Initialize empty photos array
      tables: section.tables
    }));
    formData.append("sections", JSON.stringify(sectionsWithoutPhotos));
  
    // Handle photos separately
    sections.forEach((section, sectionIndex) => {
      if (section.photos && section.photos.length > 0) {
        section.photos.forEach(photo => {
          formData.append('photos', photo);
          formData.append('photoSectionIndices', sectionIndex);
        });
      }
    });
  
    try {
      const response = await axios.post(
        "https://news-4ol1.onrender.com/api/news/post",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      
      console.log("Response:", response.data); // Add this for debugging
      alert("Article submitted successfully!");
      navigate("/admin/dashboard");
    } catch (error) {
      console.error("Error submitting article:", error);
      console.error("Error response:", error.response); // Add this for debugging
      alert("Failed to submit article. " + (error.response?.data?.message || error.message));
    }
  };
  const handleTableConfigChange = (e) => {
    setTableConfig((prevConfig) => ({
      ...prevConfig,
      [e.target.name]: parseInt(e.target.value),
    }));
  };
  
  return (
    <div className="min-h-screen bg-gradient-to-br from-neutral-950 to-neutral-900 flex items-center justify-center p-6" dir="rtl">
      <div className="bg-white/95 backdrop-blur-sm shadow-2xl rounded-lg p-8 w-full max-w-4xl border-r-4 border-green-600">
        <h1 className="text-3xl font-bold mb-8 text-neutral-900 border-b pb-4 flex items-center">
          <span className="text-green-600 ml-2">|</span> إنشاء مقال جديد
        </h1>

        <form onSubmit={handleSubmit} className="space-y-8">
          <div className="space-y-2">
            <label className="block text-sm font-semibold text-neutral-800 tracking-wider">العنوان الرئيسي</label>
            <input
              type="text"
              name="mainTitle"
              value={formDetails.mainTitle}
              onChange={handleFormDetailsChange}
              placeholder="أدخل العنوان الرئيسي"
              className="w-full px-4 py-3 border-2 border-neutral-200 focus:border-green-600 rounded-lg"
            />
          </div>

          <div className="space-y-2">
            <label className="block text-sm font-semibold text-neutral-800 tracking-wider">العنوان الفرعي</label>
            <input
              type="text"
              name="underTitle"
              value={formDetails.underTitle}
              onChange={handleFormDetailsChange}
              placeholder="أدخل العنوان الفرعي"
              className="w-full px-4 py-3 border-2 border-neutral-200 focus:border-green-600 rounded-lg"
            />
          </div>

          <div className="space-y-2">
  <label className="block text-sm font-semibold text-neutral-800 tracking-wider">التصنيفات</label>
  <div className="flex flex-wrap gap-2">
    {predefinedCategories.map((category) => (
      <div key={category} className="flex items-center">
        <input
          type="checkbox"
          id={`category-${category}`}
          name="categories"
          value={category}
          checked={selectedCategories.includes(category)}
          onChange={() => handleCategoryChange(category)}
          className="mr-2"
        />
        <label htmlFor={`category-${category}`} className="text-sm">{category}</label>
      </div>
    ))}
  </div>
</div>
          {sections.map((section, index) => (
            <div key={index} className="p-6 border-2 border-neutral-200 rounded-lg space-y-6 bg-white">
              <div className="space-y-2">
                <label className="block text-sm font-semibold text-neutral-800 tracking-wider">عنوان القسم</label>
                <input
                  type="text"
                  name="title"
                  value={section.title}
                  onChange={(e) => handleSectionChange(index, e)}
                  placeholder="عنوان القسم"
                  className="w-full px-4 py-3 border-2 border-neutral-200 focus:border-green-600 rounded-lg"
                />
              </div>

              <textarea
                name="content"
                value={section.content}
                onChange={(e) => handleSectionChange(index, e)}
                placeholder="محتوى القسم"
                className="w-full px-4 py-3 border-2 border-neutral-200 focus:border-green-600 rounded-lg h-32"
              />

<div className="space-y-4">
 <input
  type="file"
  multiple
  onChange={(e) => handleFileUpload(e, index)}
  className="hidden"
  id={`file-upload-${index}`}
  accept="image/*,video/*"  // Updated to accept both images and videos
/>

  <label
    htmlFor={`file-upload-${index}`}
    className="flex items-center justify-center w-full h-32 px-4 transition bg-white border-2 border-gray-300 border-dashed rounded-md appearance-none cursor-pointer hover:border-green-500 focus:outline-none"
  >
    <div className="flex flex-col items-center space-y-2">
      <svg className="w-6 h-6 text-gray-600" stroke="currentColor" fill="none" viewBox="0 0 48 48">
        <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
      <span className="text-sm text-gray-600">اختر الصور أو اسحبها هنا</span>
    </div>
  </label>

  {section.photos.map((media, photoIndex) => (
  <div key={photoIndex} className="relative group">
    {media.type.startsWith('image/') ? (
      <img
        src={URL.createObjectURL(media)}
        alt="Uploaded"
        className="w-full h-48 object-cover rounded-lg shadow-md transition-transform duration-300 group-hover:scale-105"
      />
    ) : (
      <video
        src={URL.createObjectURL(media)}
        className="w-full h-48 object-cover rounded-lg shadow-md transition-transform duration-300 group-hover:scale-105"
        controls
      />
    )}
    <button
      type="button"
      onClick={() => handleRemovePhoto(index, photoIndex)}
      className="absolute top-2 right-2 p-1.5 bg-green-500 text-white rounded-full opacity-0 group-hover:opacity-100 transition-opacity duration-300"
    >
      <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
      </svg>
    </button>
  </div>
))}

</div>


<div className="flex flex-col w-full space-y-8">
  <button
    type="button"
    onClick={() => handleOpenTableModal(index)}
    className="flex items-center gap-2 px-6 py-3 bg-gradient-to-r from-green-800 to-green-500 text-white rounded-xl hover:from-green-500 hover:to-green-800 transition-all duration-300 w-fit shadow-lg hover:shadow-xl"
  >
    <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7" />
    </svg>
    إضافة جدول جديد
  </button>

  {section.tables.map((table, tableIndex) => (
    <div key={tableIndex} className="bg-white rounded-2xl  p-8  hover:transition-all duration-300">
      <div className="flex items-center justify-between mb-6 border-b border-gray-100 pb-4">
        <div className="flex-1 ml-4">
          <input
            type="text"
            value={table.title}
            onChange={(e) => handleTableTitleChange(index, tableIndex, e)}
            placeholder="عنوان الجدول"
            className="text-2xl font-bold px-4 py-2 w-full bg-transparent border-b-2 border-transparent focus:border-amber-500 outline-none transition-all duration-200"
          />
        </div>
        <button
          type="button"
          onClick={() => handleRemoveTable(index, tableIndex)}
          className="flex items-center gap-2 px-4 py-2 text-green-500 hover:text-green-700 hover:bg-green-50 rounded-lg transition-all duration-200"
        >
          <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
          </svg>
          حذف الجدول
        </button>
      </div>
      
      <div className="overflow-x-auto rounded-xl border border-gray-200">
        <table className="w-full border-collapse bg-white">
          <tbody>
            {table.cells.map((row, rowIndex) => (
              <tr key={rowIndex} className="border-b border-gray-200 last:border-0">
                {row.map((cell, colIndex) => (
                  <td key={colIndex} className="border-r border-gray-200 last:border-0">
                    <input
                      type="text"
                      value={cell}
                      onChange={(e) => handleTableCellChange(index, tableIndex, rowIndex, colIndex, e.target.value)}
                      className="w-full p-4 bg-transparent hover:bg-amber-50 focus:bg-amber-100 transition-colors duration-200 outline-none text-center"
                      placeholder="أدخل البيانات"
                    />
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      
      <div className="mt-4 text-sm text-gray-500 text-center">
        * انقر على الخلايا لتعديل المحتوى
      </div>
    </div>
  ))}
</div>

  
                <button
                  type="button"
                  onClick={() => handleRemoveSection(index)}
                  className="text-green-600"
                >
                  إزالة القسم
                </button>
              </div>
            ))}
  
            <div className="flex justify-between">
              <button
                type="button"
                onClick={handleAddSection}
                className="px-6 py-3 bg-black text-white rounded-lg"
              >
                إضافة قسم
              </button>
  
              <button
                type="submit"
                className="px-6 py-3 bg-green-600 text-white rounded-lg"
              >
                نشر المقال
              </button>
            </div>
          </form>
        </div>
  
        {isTableModalOpen && (
          <div className="fixed inset-0 bg-black/50 flex items-center justify-center">
            <div className="bg-white rounded-lg shadow-xl p-6 max-w-md w-full">
              <h2 className="text-2xl font-bold mb-4">إعداد الجدول</h2>
              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-semibold mb-1">عدد الصفوف:</label>
                  <input
                    type="number"
                    name="rows"
                    value={tableConfig.rows}
                    onChange={handleTableConfigChange}
                    className="w-full px-4 py-3 border-2 border-neutral-200 focus:border-green-600 rounded-lg"
                    min="1"
                  />
                </div>
  
                <div>
                  <label className="block text-sm font-semibold mb-1">عدد الأعمدة:</label>
                  <input
                    type="number"
                    name="columns"
                    value={tableConfig.columns}
                    onChange={handleTableConfigChange}
                    className="w-full px-4 py-3 border-2 border-neutral-200 focus:border-green-600 rounded-lg"
                    min="1"
                  />
                </div>
  
                <div className="flex justify-end space-x-4">
                  <button
                    type="button"
                    onClick={() => setIsTableModalOpen(false)}
                    className="px-6 py-3 bg-gray-300 text-neutral-800 rounded-lg hover:bg-gray-400"
                  >
                    إلغاء
                  </button>
                  <button
                    type="button"
                    onClick={handleAddTable}
                    className="px-6 py-3 bg-green-600 text-white rounded-lg hover:bg-green-700"
                  >
                    إضافة جدول
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
  
