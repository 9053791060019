import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, Link } from "react-router-dom";
import { FaNewspaper, FaCalendarAlt, FaBookOpen, FaTable, FaImage, FaShareAlt, FaArrowLeft } from "react-icons/fa";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";

export default function Article() {
  const [article, setArticle] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [activeImage, setActiveImage] = useState(null);
  const [articles, setArticles] = useState([]);
  const [imageLoading, setImageLoading] = useState(true);

  const { id } = useParams();

   useEffect(() => {
    const fetchArticle = async () => {
      try {
        const response = await axios.get(`https://news-4ol1.onrender.com/api/news/${id}`);
       
        setArticle(response.data);
      } catch (err) {
        console.error("Error fetching article:", err); // Debug log
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    const fetchLastArticles = async () => {
      try {
        const response = await axios.get("https://news-4ol1.onrender.com/api/news/");
        setArticles(response.data.slice(0, 4));
      } catch (err) {
        console.error("Error fetching latest articles:", err); // Debug log
        setError(err.message);
      }
    };

    fetchArticle();
    fetchLastArticles();
  }, [id]);

  const copyLink = () => {
    navigator.clipboard.writeText(window.location.href).then(() => {
      alert("Link copied to clipboard!");
    }).catch((err) => {
      console.error("Failed to copy: ", err);
    });
  };

  const getFirstMedia = (article) => {
    if (article.sections && article.sections.length > 0) {
      // Loop through all sections to find the first available media
      for (const section of article.sections) {
        if (section.photos && section.photos.length > 0) {
          const media = section.photos.find(photo => photo.cloudinaryUrl);
          if (media) {
            // Check if the media is a video (e.g., ends with .mp4, .mov, etc.)
            if (media.cloudinaryUrl.match(/\.(mp4|mov|avi|mkv)$/i)) {
              // Generate a thumbnail URL for the video
              const videoUrl = media.cloudinaryUrl;
              const thumbnailUrl = videoUrl.replace(/\.(mp4|mov|avi|mkv)$/i, '.jpg');
              return { type: 'video', url: thumbnailUrl, videoUrl };
            } else {
              // It's an image
              return { type: 'image', url: media.cloudinaryUrl };
            }
          }
        }
      }
    }
    return null;
  };


  if (loading)
    return (
      <div className="flex justify-center items-center h-screen bg-gradient-to-tr from-green-50 via-white to-yellow-50">
        <div className="relative">
          <div className="animate-spin rounded-full h-20 w-20 border-4 border-gray-200"></div>
          <div className="absolute top-0 left-0 animate-spin rounded-full h-20 w-20 border-t-4 border-green-500"></div>
        </div>
      </div>
    );

  if (error)
    return (
      <div className="flex justify-center items-center h-screen bg-gradient-to-tr from-green-50 via-white to-yellow-50">
        <div className="bg-white p-8 rounded-2xl shadow-lg">
          <p className="text-green-500 font-bold text-lg">{error}</p>
        </div>
      </div>
    );

  return (
    <>
      <NavBar />
      <div dir="rtl" className="min-h-screen bg-gradient-to-tr from-green-50 via-white to-yellow-50 mt-20">
        {activeImage && (
          <div 
            className="fixed inset-0 z-50 bg-black/90 flex items-center justify-center p-4"
            onClick={() => setActiveImage(null)}
          >
            <img 
              src={activeImage}
              alt="Enlarged view"
              className="max-w-full max-h-[90vh] object-contain rounded-lg"
            />
          </div>
        )}

        <div className="max-w-4xl mx-auto px-4 py-12">
          <article className="bg-white rounded-3xl shadow-xl overflow-hidden transform transition-all duration-300 hover:shadow-2xl">
            <header className="relative px-8 pt-12 pb-8">
              <div className="absolute top-0 right-0 w-full h-1 bg-gradient-to-r from-green-500 to-yellow-400"></div>
              
              <div className="flex items-center gap-3 text-green-600 mb-6 animate-fade-in">
                <FaNewspaper className="text-2xl" />
                <span className="text-sm font-semibold tracking-wider uppercase">مقال مميز</span>
              </div>

              <h1 className="text-4xl sm:text-5xl font-bold mb-6 bg-gradient-to-r from-gray-900 to-gray-700 bg-clip-text text-transparent font-arabic leading-tight">
                {article.mainTitle}
              </h1>

              <div className="flex items-center justify-between text-gray-500 text-sm mb-6">
                <div className="flex items-center gap-3">
                  <FaCalendarAlt className="text-yellow-500" />
                  <time className="text-xs text-gray-500" dir="ltr">
                          {new Date(article.createdAt).toLocaleDateString("ar-TN", {
                           
                           day: "numeric",
                            month: "long",
                            year: "numeric",

                          })}
                        </time>
                </div>
                <button 
        onClick={copyLink} 
        className="flex items-center gap-2 px-4 py-2 rounded-full bg-gray-50 hover:bg-green-50 transition-colors duration-200"
      >
        <FaShareAlt className="text-green-500" />
        <span>مشاركة</span>
      </button>
              </div>

              <h2 className="text-xl text-gray-600 font-arabic leading-relaxed">{article.underTitle}</h2>
            </header>

            <div className="h-px bg-gradient-to-r from-transparent via-gray-200 to-transparent"></div>

            {article.sections?.map((section, sectionIndex) => (
              <section key={sectionIndex} className="px-8 py-10">
                {section.title && (
                  <div className="flex items-center gap-3 mb-8">
                    <FaBookOpen className="text-green-600 text-2xl" />
                    <h3 className="text-2xl font-bold text-gray-800 font-arabic">
                      {section.title}
                    </h3>
                  </div>
                )}

                <div className="prose prose-lg max-w-none">
                  {section.content && section.content.split("\n").map((paragraph, idx) => (
                    paragraph.trim() && (
                      <p key={idx} className="text-gray-700 leading-relaxed mb-6 font-arabic transition-colors duration-200 hover:text-gray-900">
                        {paragraph}
                      </p>
                    )
                  ))}
                </div>

                {section.photos?.length > 0 && (
                  <div className="mt-10">
                    <div className="flex items-center gap-2 mb-6">
                      <FaImage className="text-yellow-500" />
                      <h4 className="text-xl font-bold text-gray-800 font-arabic">معرض الصور والفيديوهات</h4>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                      {section.photos.map((photo, photoIndex) => (
                        <div 
                          key={photoIndex} 
                          className="group relative rounded-2xl overflow-hidden cursor-pointer transform transition-all duration-300 hover:scale-[1.02]"
                        >
                          {photo.cloudinaryUrl && (
                            photo.cloudinaryUrl.toLowerCase().match(/\.(mp4|webm|ogg)$/i) ? (
                              <video
                                src={photo.cloudinaryUrl}
                                controls
                                className="w-full h-64 object-cover rounded-2xl"
                              />
                            ) : (
                              <>
                                <img
                                  src={photo.cloudinaryUrl}
                                  alt={`Section ${sectionIndex + 1} Image ${photoIndex + 1}`}
                                  className={`w-full h-64 object-cover rounded-2xl transition-opacity duration-300 ${
                                    imageLoading ? 'opacity-0' : 'opacity-100'
                                  }`}
                                  onClick={() => setActiveImage(photo.cloudinaryUrl)}
                                  onLoad={() => setImageLoading(false)}
                                  onError={(e) => {
                                    setImageLoading(false);
                                    console.error('Image failed to load:', photo.cloudinaryUrl);
                                    e.target.src = '/fallback-image.jpg'; // Add your fallback image
                                  }}
                                />
                                {imageLoading && (
                                  <div className="absolute inset-0 bg-gray-100 animate-pulse rounded-2xl" />
                                )}
                              </>
                            )
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                )}

                {section.tables?.map((table, tableIndex) => (
                  <div key={tableIndex} className="mt-10">
                    <div className="flex items-center gap-2 mb-6">
                      <FaTable className="text-green-600" />
                      {table.title && (
                        <h4 className="text-xl font-bold text-gray-800 font-arabic">
                          {table.title}
                        </h4>
                      )}
                    </div>

                    <div className="overflow-x-auto rounded-2xl border border-gray-100 shadow-lg">
                      <table className="w-full">
                        <tbody>
                          {table.cells?.map((row, rowIndex) => (
                            <tr 
                              key={rowIndex} 
                              className={`
                                border-b border-gray-100
                                ${rowIndex === 0 ? 'bg-green-50' : 'hover:bg-green-50'}
                              `}
                            >
                              {row.map((cell, cellIndex) => (
                                <td
                                  key={cellIndex}
                                  className="py-4 px-6 text-center text-gray-700 transition-colors duration-200 font-arabic"
                                >
                                  {cell}
                                </td>
                              ))}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                ))}
              </section>
            ))}
          </article>

          <div className="mt-16 mx-4 lg:mx-auto">
            <div className="bg-white rounded-3xl shadow-lg p-6 sm:p-8">
              <h3 className="text-2xl sm:text-3xl font-bold mb-8 text-center bg-gradient-to-r from-green-600 to-green-800 bg-clip-text text-transparent">
                آخر المقالات
              </h3>
              
              <div className="grid grid-cols-1 gap-6">
  {articles.map((lastArticle, index) => {
    const media = getFirstMedia(lastArticle); // Use getFirstMedia here
    return (
      <Link
        key={index}
        to={`/admin/article/${lastArticle._id}`}
        className="group block"
      >
        <article className="flex flex-col sm:flex-row bg-gradient-to-br from-gray-50 to-green-50 rounded-2xl border border-gray-100 overflow-hidden transition-all duration-300 hover:border-green-200 hover:shadow-lg">
          <div className="relative w-full sm:w-48 h-48 sm:h-auto">
            {media ? (
              media.type === 'video' ? (
                <img
                  src={media.url}
                  alt={lastArticle.mainTitle}
                  className="w-full h-full object-cover transition-all duration-300 opacity-100 group-hover:scale-105"
                />
              ) : (
                <img
                  src={media.url}
                  alt={lastArticle.mainTitle}
                  className={`w-full h-full object-cover transition-all duration-300 ${
                    imageLoading ? 'opacity-0' : 'opacity-100 group-hover:scale-105'
                  }`}
                  onLoad={() => setImageLoading(false)}
                  onError={(e) => {
                    setImageLoading(false);
                    console.error('Image failed to load:', media.url);
                    e.target.src = '/fallback-image.jpg'; // Add your fallback image
                  }}
                />
              )
            ) : (
              <div className="w-full h-full bg-gray-100 flex items-center justify-center">
                <svg className="w-12 h-12 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
                </svg>
              </div>
            )}
            {imageLoading && (
              <div className="absolute inset-0 bg-gray-100 animate-pulse" />
            )}
            <div className="absolute inset-0 bg-gradient-to-t from-black/20 to-transparent sm:hidden"></div>
          </div>

          <div className="flex-1 p-4 sm:p-6 flex flex-col justify-between">
            <div>
              <h4 className="text-xl font-bold text-gray-800 group-hover:text-green-700 transition-colors duration-300 mb-2 line-clamp-2">
                {lastArticle.mainTitle}
              </h4>
              <p className="text-sm text-gray-600 line-clamp-2 mb-4">
                {lastArticle.underTitle}
              </p>
            </div>

            <div className="flex items-center justify-between">
              <div className="flex items-center text-green-600 text-sm">
                <FaCalendarAlt className="ml-2" />
                <time className="text-xs text-gray-500" dir="ltr">
                          {new Date(article.createdAt).toLocaleDateString("ar-TN", {
                           
                           day: "numeric",
                            month: "long",
                            year: "numeric",

                          })}
                        </time>
              </div>
              
              <span className="inline-flex items-center text-green-600 text-sm font-medium group-hover:translate-x-2 transition-transform duration-300">
                اقرأ المزيد
                <FaArrowLeft className="mr-2 text-xs" />
              </span>
            </div>
          </div>
        </article>
      </Link>
    );
  })}
</div>
              <div className="mt-8 text-center">
                <Link
                  to="/"
                  className="inline-flex items-center justify-center px-6 py-3 text-sm font-medium text-white bg-green-600 rounded-full hover:bg-green-700 transition-colors duration-300"
                >
                  عرض جميع المقالات
                  <FaArrowLeft className="mr-2 text-xs" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}